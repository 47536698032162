import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { CreateOrderType, OrderVariantType } from '../../../types/order';
import { ChannelsInOrderType } from '../../../types/channels';

import { OptionType } from 'types/global';

import type { PayloadAction } from '@reduxjs/toolkit';

interface OrderState {
  order: CreateOrderType;
  isCreationProgress: boolean;
  orderVariant: OrderVariantType;
  tags: OptionType[];
  options: OptionType[];
}

const initialState: OrderState = {
  order: {
    amountCompany: 0,
    channels: [],
    endDate: null,
    frequency: 0,
    createdAt: null,
    creatorId: '',
    startDate: null,
    status: 'DRAFT',
    tz: '',
    link: 'https://google.com',
    linkName: 'test',
  },
  isCreationProgress: false,
  orderVariant: 'BANNER',
  tags: [],
  options: [{ value: '$channelLink', label: '$channelLink' }],
};

export const createOrderSlice = createSlice({
  name: 'createOrderSlice',
  initialState,
  reducers: {
    setDates: (state, action: PayloadAction<{ startDate: string; endDate: string; tz: string }>) => {
      state.order.startDate = action.payload.startDate;
      state.order.endDate = action.payload.endDate;
      state.order.tz = action.payload.tz;
    },
    updateChannels: (state, action: PayloadAction<ChannelsInOrderType[]>) => {
      state.order.channels = action.payload;
    },
    setIsCreationProgressOrder: (state, action: PayloadAction<boolean>) => {
      state.isCreationProgress = action.payload;
    },
    updateOrderVariant: (state, action: PayloadAction<OrderVariantType>) => {
      state.orderVariant = action.payload;
    },
    updateTagsCreateOrder: (state, action: PayloadAction<OptionType[]>) => {
      state.tags = action.payload;
    },
  },
});

export const createOrderActions = createOrderSlice.actions;

export const selectCreateOrder = (state: RootState) => state.order;

export default createOrderSlice.reducer;
