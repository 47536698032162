import { RouterProvider } from 'react-router-dom';
import { Suspense } from 'react';
import { ConfigProvider } from 'antd';
import dayjs from 'dayjs';

import ruLocale from 'antd/locale/ru_RU';

import { router } from './features/router';
import { Loader } from './components/loader';

import { LocaleContext } from 'features/context/localeContext';
import { useLocale } from 'features/hooks/useLocale';
import { TimeZoneContext } from 'features/context/TimeZoneContext';
import { useDateFormatTZ } from 'features/hooks/useDateFormatTZ';

dayjs.locale('ru', { weekStart: 1 });

export const App = () => {
  const { getStatusRemoveBanners, isPending, locale } = useLocale();
  const { formatDateInNewTz, timeOfPlacement } = useDateFormatTZ();

  return (
    <Suspense fallback={<Loader />}>
      <ConfigProvider locale={ruLocale}>
        <LocaleContext.Provider value={{ getStatusRemoveBanners, isPending, locale }}>
          <TimeZoneContext.Provider value={{ formatDateInNewTz, timeOfPlacement: timeOfPlacement || null }}>
            <RouterProvider router={router} />
          </TimeZoneContext.Provider>
        </LocaleContext.Provider>
      </ConfigProvider>
    </Suspense>
  );
};
