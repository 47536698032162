import { ChannelCategories, ChannelStatusType } from '../types/channels';
import { TransactionStatus, TransactionTypes } from '../types/transaction';

export const isDev = process.env.NODE_ENV === 'development';

export const LOCALE = process.env.REACT_APP_LOCALE;

export const STORAGE_KEY_PAGE_MAIN = 'page_main';
export const STORAGE_KEY_PAGE_CHANNELS = 'page_channels';
export const STORAGE_KEY_PAGE_ORDERS = 'page_orders';
export const STORAGE_KEY_LANG_KEY = 'STORAGE_KEY_LANG_KEY';
export const STORAGE_KET_PRICE_CALC_TIMER = 'STORAGE_KET_PRICE_CALC_TIMER';
export const STORAGE_KET_PRICE_CALC_TIMESTAMP = 'STORAGE_KET_PRICE_CALC_TIMESTAMP';

export const SUPER_ADMIN = '5f7bf4c0-c84a-493c-ba58-892340c62a78';
export const ADMIN = '5f7bf4c0-c84a-493c-ba58-892340c62a71';
export const CHANNEL_ADMIN = '5f7bf4c0-c84a-493c-ba58-892340c62a21';
export const ACCOUNT_MANAGER = '5f7bf4c0-c84a-493c-ba58-892340c62a73';
export const BUYER = '5f7bf4c0-c84a-493c-ba58-892340c62a12';
export const GUEST = '5f7bf4c0-c84a-493c-ba58-892340c64a43';
export const PROGRAMMER = '5f7bf4c0-c84a-493c-ba58-892340c64a73';

export const ACCESS_TOKEN_KEY = 'ACCESS_TOKEN_KEY';

export const WIDGET_NAME_DEV = 'telegram-login-GramJs23Bot';
export const WIDGET_NAME_PROD = process.env.REACT_APP_BOT_NAME_WIDGET_NAME!;

export const BOT_NAME_DEV = 'GramJs23Bot';
export const BOT_NAME_PROD = process.env.REACT_APP_BOT_NAME!;

export const categories = [
  {
    label: 'Тестовый',
    value: 'test',
  },
  {
    label: 'Продуктовый',
    value: 'real',
  },
];

export const getCategoryName: { [key in ChannelCategories]: string } = {
  test: 'Тестовый',
  real: 'Продуктовый',
};

export const channelStatusTranslate: { [key in ChannelStatusType]: string } = {
  APPROVED: 'Одобрено',
  AWAITING_APPROVAL: 'Ожидает одобрения',
  REJECTED: 'Отклонено',
  STOPPED: 'Остановлен',
};

export const getColorByStatus: { [key in ChannelStatusType]: string } = {
  APPROVED: 'status__approved',
  AWAITING_APPROVAL: 'status__waiting',
  REJECTED: 'status__rejected',
  STOPPED: 'Остановлен',
};

export const translateTransactionStatus: { [key in TransactionStatus]: string } = {
  CANCEL: 'Отменено',
  CONFIRM: 'Подтверждено',
  WAITING: 'Ожидает подтверждения',
};

export const translateTransactionTypes: { [key in TransactionTypes]: string } = {
  ACCRUAL: 'Начисление',
  WRITE_OFF: 'Списание',
};

export const LINK_TO_MAIN = '/';
export const LINK_TO_MARGIN_REPORT = '/margin_report';
export const LINK_TO_CHANNELS = '/channels';
export const LINK_TO_CREATE_ORDER = '/create_order';
export const LINK_TO_DICTIONARY = '/dictionary';
export const LINK_TO_ORDER_INFO = '/order_info/:id';
export const LINK_TO_ORDERS = '/orders';
export const LINK_TO_PAYMENT_CONFIRMATION = '/payment_confirmation';
export const LINK_TO_PRICE_CALCULATOR = '/price_calculator';
export const LINK_TO_PRICES = '/prices';
export const LINK_TO_REPORT_ACCOUNTING = '/report_accounting';
export const LINK_TO_TIME_OF_PLACEMENT = '/time_of_placement';
export const LINK_TO_CHANNELS_STATISTICS = '/channels_statistics';
export const LINK_TO_ROLE_MANAGEMENT = '/role_management';
export const LINK_TO_STATISTIC = '/statistic/:orderId';
export const LINK_TO_REPORT_ON_BUYER = '/report_on_buyers';

export const PERMISSION_MAIN_PAGE = '18fd50ec-5b7b-4547-b552-d6b0006869c6';
export const PERMISSION_CREATE_ORDER = '119711e2-2ab7-429f-ae5c-9b73aadecaf0';
export const PERMISSION_VIEW_TEST_CHANNELS_ON_MAIN_PAGE = '2204cf0c-dc27-4694-8c7e-d09e762fa6f8';
export const PERMISSION_VIEW_ORDERS_AND_VIEW_ORDER = '83435bda-b43e-459e-ba21-cf57b31d2620';
export const PERMISSION_CHANGE_ORDER = 'bf688d21-2c81-4337-b997-04a0db04bc03';
export const PERMISSION_TECHNICAL_MESSAGES = '868dafa4-0c9f-4b5e-971f-bc7f76d84e37';
export const PERMISSION_ADD_CHANNELS = 'ac340606-f2bb-49dd-b621-c8bc20582d7c';
export const PERMISSION_CHANGE_AND_VIEW_PRICE_SELLING = 'b852472a-7d20-44ab-b909-4c2edfc6c21b';
export const PERMISSION_CHANGE_AND_VIEW_PRICE_BUYING = 'a2347d26-9b78-43b7-8cba-c4f6966389a2';
export const PERMISSION_VIEW_MARGIN = '0d64274e-fea4-4fc2-8f11-c8e96cdd502b';
export const PERMISSION_VIEWS_NO_TEST_CHANNELS_ON_ALL_CHANNELS = '8bf004d9-c4f4-468b-8f63-1016289a7f21';
export const PERMISSION_VIEW_CHANNEL_PRICES = '5bec2b74-adb6-4894-bfa3-5a6c1a79e056';
export const PERMISSION_VIEW_DICTIONARY = '8de59ba2-6f0f-45da-bd49-22ab0db960a0';
export const PERMISSION_ADD_STOP_WORDS = '939d91c5-a964-4f35-978f-d9b86996e98e';
export const PERMISSION_VIEW_MARGIN_REPORT = 'ce53ca9c-ac77-4920-bf55-026fd4bc968e';
export const PERMISSION_VIEW_REPORT_ACCOUNTING = '9b149a51-a8d0-461c-938b-929f48cc292f';
export const PERMISSION_VIEW_ACCEPT_TRANSACTIONS = '82299158-7c13-4de5-8e97-90381272549c';
export const PERMISSION_VIEW_CALC_PRICES = '4c6d4fba-d5f9-45cb-b0c0-93d578052ce6';
export const PERMISSION_VIEW_PERMISSIONS = '87efa15f-d6b2-459a-acc3-436c6a7abfda';
export const PERMISSION_CHANGE_PERMISSIONS = '41d9f610-47bd-4653-867b-04539492d1c4';

export const COLORS = {
  BANNER: '#2996ce',
  REPOST: '#6029cd',
  AVATAR: '#6029cd',
};

export const USER_LOCALE = Intl.DateTimeFormat().resolvedOptions().locale;

export const LOCALES = {
  ru: '4623c2e6-93ea-4b2b-9d5d-0f8ae21576fb',
  uz: 'b2552573-bcac-490a-81fe-258aebd6f68c',
  dev: '699ddf83-61ac-4a94-ad53-75b85c164787',
};

export const isRuLocale = process.env.REACT_APP_LOCALE_ID === LOCALES.ru;
export const isUzLocale = process.env.REACT_APP_LOCALE_ID === LOCALES.uz;
export const isDevLocale = process.env.REACT_APP_LOCALE_ID === LOCALES.dev;

export const SERVER_PATH = isDev ? process.env.REACT_APP_SERVER_URL_DEV : process.env.REACT_APP_SERVER_URL_PROD;
