import { Dayjs } from 'dayjs';
import { createContext } from 'react';

import { TimeOfPlacementType } from 'types/typeOfPlacement';

export type TimeZoneContextType = {
  formatDateInNewTz: (date: Dayjs) => Dayjs | string;
  timeOfPlacement: TimeOfPlacementType | null;
};

export const TimeZoneContext = createContext<TimeZoneContextType>({
  formatDateInNewTz: () => '',
  timeOfPlacement: null,
});
