import { useMutation } from '@tanstack/react-query';

import { timeOfPlacementService } from '../service';
import { UpdateTimeOfPlacementBodyType } from '../types';

export const useUpdateTimeOfPlacement = () => {
  return useMutation({
    mutationFn: (data: UpdateTimeOfPlacementBodyType) => timeOfPlacementService.updateTimeOfPlacement(data),
  });
};
