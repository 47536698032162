import { useQuery } from '@tanstack/react-query';

import { timeOfPlacementService } from '../service';
import { QUERY_KEY_GET_TIME_OF_PLACEMENT } from '../../keys';

export const useGetTimeOfPlacement = () => {
  return useQuery({
    queryKey: [QUERY_KEY_GET_TIME_OF_PLACEMENT],
    queryFn: () => timeOfPlacementService.getTimeOfPlacement(),
  });
};
