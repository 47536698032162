import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { useGetTimeOfPlacement } from 'api/timeOfPlacement';

dayjs.extend(utc);
dayjs.extend(timezone);

export const useDateFormatTZ = () => {
  const { data } = useGetTimeOfPlacement();

  const formatDateInNewTz = (date: Dayjs) => {
    if (!data?.timeOfPlacement.tz) return date.utc().toISOString();

    // Приводим дату к таймзоне timeOfPlacement.tz
    return date.tz(data.timeOfPlacement.tz);
  };

  return {
    formatDateInNewTz,
    timeOfPlacement: data?.timeOfPlacement,
  };
};
