import { AxiosResponse } from 'axios';

import { GetTimeOfPlacementResType, UpdateTimeOfPlacementBodyType, UpdateTimeOfPlacementResType } from './types';

import { request } from '../axios';

export const timeOfPlacementService = {
  getTimeOfPlacement: async () => {
    return request.get<void, AxiosResponse<GetTimeOfPlacementResType>>('/time-of-placement').then((res) => res.data);
  },
  updateTimeOfPlacement: async (data: UpdateTimeOfPlacementBodyType) => {
    return request
      .post<UpdateTimeOfPlacementBodyType, AxiosResponse<UpdateTimeOfPlacementResType>>(`/time-of-placement`, data)
      .then((res) => res.data);
  },
};
