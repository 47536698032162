import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../store';

import { OrderVariantType } from '../../../types/order';

import type { PayloadAction } from '@reduxjs/toolkit';

interface OrdersState {
  currentClient: string | null;
  isTest: false | undefined;
  variant: OrderVariantType;
}

const initialState: OrdersState = {
  currentClient: null,
  isTest: false,
  variant: 'BANNER',
};

export const ordersFilterSlice = createSlice({
  name: 'orders-filter',
  initialState,
  reducers: {
    setCurrentClientInOrdersFilter(state, action: PayloadAction<string | null>) {
      state.currentClient = action.payload;
    },
    setIsTestOrderFilter(state, action: PayloadAction<false | undefined>) {
      state.isTest = action.payload;
    },
    setOrderVariant(state, action: PayloadAction<OrderVariantType>) {
      state.variant = action.payload;
    },
  },
});

export const ordersFilterActions = ordersFilterSlice.actions;

export const selectCreateOrdersFilter = (state: RootState) => state.ordersFilter;

export default ordersFilterSlice.reducer;
